import Page from './Page.js';

function importAll(r) {
  return r.keys().map(r);
}

const photos = importAll(require.context('./img/gallery', false, /\.(png|jpe?g|svg)$/));

const WIDTH = "480px"

const photoGallery = photos.map((photoURL, index) =>
  <img src={photoURL} width={WIDTH} alt="" key={index} />
);


function PagePhotos() {
  return (
    <Page>
      <p>
        {photoGallery}
      </p>
    </Page>
  );
}

export default PagePhotos;
