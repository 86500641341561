import Page from './Page.js';
import logo_full from "./img/logo_full.png";

function PageProgram() {
  return (
    <Page>
      <div>
        <img src={logo_full} width="480em" alt="" />
      </div>
      <div>
        <p>The Garden of Children is an In-Home Multi-Age Childcare program that is run by a qualified ECE and assistant.</p>
        <p>Our daycare is operated at a house in the Queen Elizabeth Park area. We have large bright rooms and a big backyard with a sandbox and a children's garden (where they can plant seeds and watch them grow). We provide different indoor activities every day, and we spend lots of time exploring outdoors with all sorts of tools and materials to aid the children's development.</p>
        <p>Our philosophy is inspired by the Reggio Emilia approach. We foster children’s learning, overall well-being and development. Children contribute to the world with endless possibilities. Each child's learning experience is dynamic and unique, it stems from the child's own interests and curiosities. The child is viewed as a researcher trying to answer the questions of life.</p>
      </div>
    </Page>
  );
}

export default PageProgram;
