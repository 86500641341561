import './Navbar.css';
import React from 'react';
import logo from "./img/logo_cropped.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="Navbar">
      <div className="logo-area">
        <Link style={{hover:'pointer'}} to={"/"}><img src={logo} width="160em" alt=""/></Link>
      </div>
      <div className="links-area">
        <Link className="Button" to={"/"}>Program</Link>
        <Link className="Button" to={"/photos"}>Photos</Link>
        <Link className="Button" to={"/contact"}>Contact</Link>
      </div>
    </div>
  );
}

export default Navbar;
