import Page from './Page.js';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_mvsmup9', 'template_gy4c29a', form.current, 'ZmeUi_ewwcHZPgoMC')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>
  );
};


function PageContact() {
  return (
    <Page>
      <p>If you have any questions, would like a viewing of our daycare, or are interested in enrolling your child, you may use the form below to contact us.</p>
      <p>Also feel free to email us at <a href="mailto:contact@gardenofchildren.ca">contact@gardenofchildren.ca</a> or phone (604) 618-2796.</p>
      <ContactUs />
    </Page>
  );
}

export default PageContact;
