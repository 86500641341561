import './App.css';
import Navbar from './Navbar.js';
import PageProgram from './PageProgram.js';
import PagePhotos from './PagePhotos.js';
import PageContact from './PageContact.js';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 'program',
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<PageProgram />} />
            <Route path="photos" element={<PagePhotos />} />
            <Route path="contact" element={<PageContact />} />
          </Routes>
        </div>
      </BrowserRouter>
    )
  };
}

export default App;
